import React from "react";
import Layout from "../../containers/gatsby/layout";
import Credibility from "../../containers/sections/Credibility/Credibility";
import ServiceBlurb from "../../containers/services/ServiceBlurb/ServiceBlurb";
import ServiceFAQs from "../../containers/services/ServiceFAQs/ServiceFAQs";
import BookOnlineContainer from "../../containers/sections/BookOnline/BookOnlineContainer";
import ServicesGallery from "../../containers/services/ServicesGallery/ServicesGallery";
import SEO from "../../containers/gatsby/seo";
import ServiceHeroContainer from "../../containers/heroes/ServiceHero/ServiceHeroContainer";

const OfficeMovesPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO
      title="Professional Office Moving Service London"
      keywords={[
        `office moves london`,
        `office movers`,
        `move office london`,
        `man and van office moves`,
        `office removals`,
        `office movers london`,
        `office removal service`,
        `cheap office movers`,
      ]}
    />
    <ServiceHeroContainer
      jobName={"Office Move"}
      title={"Office Moves"}
      description={
        "Let JJD help you with your next office move, and get a receipt sent to you right away"
      }
      heroImg={require("../../assets/services-heroes/office.jpg")}
      reviewImage={require("../../assets/avatars/11.png")}
      reviewContent={
        "I am a very happy customer with JJD. The driver arrived on time, loaded and unloaded things with alacrity and charged at very low rate. I googled and contacted many 'man in van' services, but JJD offered the best rate. Highly recommended!"
      }
      reviewName={"Kiran Khan"}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={"Arrive at your new office in style"}
      blurb={
        "Office moves is one our most popular requests in London. JJD is a perfect fit for shifting all of your desks, chairs, and computers to your new office space."
      }
      blurb2={
        "Book an office move online and we'll send your company a receipt straight away. We can be at your current office within 1 hour of booking. As soon as a professional(s) is allocated to your job, we'll send you their details in case you need to call them."
      }
      blurb3={
        "We carry plenty of tools to disassemble large tables, chairs, or bubble wrap electronics safely - just let us know what you need in the description. It's best to get as much of what you are moving ready for the driver before they arrive. Parking near offices in London can be limited."
      }
    />
    <ServicesGallery
      gallerySubtitle={"Office moves are easier and affordable with JJD"}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={"FAQs"}
      faqSubTitle={"Got a question about office moves?"}
      faqs={[
        {
          question: "I need a receipt for the job, what do I have to do?",
          answer:
            "Receipts are sent automatically via email for every job at JJD. Just provide an email address in the booking process.",
        },
        {
          question: "Do you bubble wrap computers and other electronics?",
          answer:
            "We can do, yes. Simply specify that you need this in the job description when you book.",
        },
        {
          question: "Our office is on the 4th floor, is that okay?",
          answer:
            "If there is a lift in your office then there will be no extra charge. If a table cannot fit in the lift or there is no lift, please specify how many stairs there are from your office to the ground floor. We charge £10 per floor. It might be wise to move things to the ground floor before your driver arrives to save time.",
        },
        {
          question: "Do you dismantle tables for carrying?",
          answer:
            "Yes, just specify that you need this done in the job description when you book and we'll bring the tools. It is recommended that you get as much as you can packed and ready before we arrive to save time, as this will also save money.",
        },
        {
          question: "Can you bring extra people to help us move office?",
          answer:
            "Yes, choose up to 3 helpers (including the driver) when you book online or in JJD's man-and-van app. Be aware that this increases the price of the booking because we have to pay the extra helpers for their time.",
        },
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default OfficeMovesPage;
